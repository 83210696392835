.leaderboard-layout{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 11fr;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  width: 100%;
  height: 100%;
  &.next{
    .tab-item-of-l-title{
      &:first-child{
        color: rgba(var(--text-color),0.5);
        &:hover{
          color: rgb(var(--text-color));
        }
      }
      &:nth-child(2){
        color: rgb(var(--text-color));
      }
    }
    .inside-underline-b-of-title{
      [dir="ltr"] & {
        transform: translate3d(100%,0,0);
      }
      [dir="rtl"] & {
        transform: translate3d(-100%,0,0);
      }
    }
    .body-wr-right-cont{
      [dir="ltr"] & {
        transform: translate3d(-50.2%,0,0);
      }
      [dir="rtl"] & {
        transform: translate3d(50.2%,0,0);
      }
    }
  }
}
.leaderboard-title{
  grid-area: top;
  width: 100%;
  padding-bottom: 4px;
}
.inside-leaderboard-title{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding: 16px 0 12px 0;
  position: relative;
}
.tab-item-of-l-title{
  flex: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.42px;
  color: rgba(var(--text-color), 0.5);
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
  transition: color 240ms;
  &:hover{
    color: rgb(var(--text-color));
  }
  &:first-child{
    color: rgb(var(--text-color));
  }
}
.underline-block-of-title{
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-image: linear-gradient(to bottom, rgba(var(--skin-color-white), 0.3), rgba(var(--skin-color-white), 0.3)), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.3), rgba(var(--skin-color-black), 0.01));
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.inside-underline-b-of-title{
  display: block;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 2px;
  background-color: rgb(var(--line-color));
  transition: transform 240ms;
  [dir="ltr"] & {
    left: 0;
  }
  [dir="rtl"] & {
    right: 0;
  }
}
.info-section-holder{
  width: 100%;
  border-radius: 6px;
  background-image: url("../images/animating-stars.png"), linear-gradient(314deg, rgba(var(--skin-color-14),0.4) 14%, rgba(var(--global-color),0.4) 65%);
  background-origin: 0 18%;
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  box-shadow: 0 0 0 1px rgba(var(--skin-color-white),0.1) inset;
}
.freebet-wr{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 54px;
  margin-top: 14px;
  padding: 4px;
  position: relative;
  overflow: hidden;
  direction: ltr;
  .prize-fund-type-icon {
    font-size: 18px;
  }
}
.freebet-wr-spn{
  display: block;
  padding: 0 4px;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: .6px;
  text-transform: uppercase;
  color: rgb(var(--text-color));
  text-align: center;
  z-index: 1;
  //word-break: break-word;
}
.info-section{
  width: 100%;
  padding: 10px 14px 14px 14px;
}
.info-section-row{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  &:not(:last-child){
    margin-bottom: 10px;
  }
}
.info-section-row-name{
  flex: 1;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: .36px;
  color: rgba(var(--text-color),1);
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  [dir="ltr"] & {
    padding-right: 2px;
  }
  [dir="rtl"] & {
    padding-left: 2px;
  }
}
.info-section-row-data{
  flex: 0 0 auto;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: .36px;
  color: rgba(var(--text-color),1);
  direction: ltr;
  [dir="ltr"] & {
    padding-left: 2px;
  }
  [dir="rtl"] & {
    padding-right: 2px;
  }
}
.leaderboard-body{
  grid-area: bottom;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.body-wr-right-cont{
  display: grid;
  grid-template-columns: repeat(2, 6fr);
  grid-template-rows: 12fr;
  grid-gap: 0;
  width: 200%;
  height: 100%;
  position: absolute;
  top: 0;
  transition: transform 240ms;
  [dir="ltr"] & {
    left: 0;
  }
  [dir="rtl"] & {
    right: 0;
  }
}
.weekly-container{
  width: 100%;
  position: relative;
}
.inside-weekly-c-wr{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 11fr;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .weekly-promo-wr {
    width: 100%;
    position: relative;
    .weekly-promo-img {
      width: 100%;
      height: 100%;
    }

    p {
      top: 65%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
    }
  }
}
.top-of-inside-weekly-c-wr{
  grid-area: top;
  position: relative;
  overflow: hidden;
}
.bottom-of-inside-weekly-c-wr{
  grid-area: bottom;
  position: relative;
}
.weekly-wr{
  width: 100%;
  padding-bottom: 6px;
  text-transform: capitalize;
}
.prize-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }

  &.title {
    [dir="ltr"] & {
      padding: 10px 18px 10px 12px;
    }
    [dir="rtl"] & {
      padding: 10px 12px 10px 18px;
    }
    //pointer-events: none;

    > .prize-cell {
      text-transform: capitalize;
      color: rgba(var(--text-color), 0.8);
      &:first-child{
        width: 65px;
        [dir="ltr"] & {
          padding-left: 0;
          text-align: left;
        }
        [dir="rtl"] & {
          padding-right: 0;
          text-align: right;
        }
      }
      &:last-child{
        width: 100px;
        direction: ltr;

        [dir="ltr"] & {
          padding-right: 0;
        }
        [dir="rtl"] & {
          padding-left: 0;
        }
      }
    }
  }

  &.item {
    padding: 12px;
    border-radius: 6px;
    //background-image: linear-gradient(to bottom, rgba(var(--skin-color-white), 0.02), rgba(var(--skin-color-white), 0.02)), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.02), rgba(var(--skin-color-white), 0.02));
    background-color: rgb(var(--info-row-color));

    &.active {
      //background-image: linear-gradient(to bottom, rgb(var(--skin-color-14)), rgb(var(--skin-color-3)) 259%), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.5), rgba(var(--skin-color-white), 0.4));
      background-color: rgba(var(--info-row-color), 0.5);
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      bottom: 0;
      z-index: 2;
    }
  }
}
.prize-cell{
  display: table-cell;
  vertical-align: middle;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: rgb(var(--text-color));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  [dir="ltr"] & {
    text-align: right;
  }
  [dir="rtl"] & {
    text-align: left;
  }
  //word-break: break-all;
  &:first-child{
    width: 30px;
    [dir="ltr"] & {
      padding-left: 0;
      text-align: left;
    }
    [dir="rtl"] & {
      padding-right: 0;
      text-align: right;
    }
  }
  &:last-child{
    width: 100px;
    direction: ltr;

    [dir="ltr"] & {
      padding-right: 0;
    }
    [dir="rtl"] & {
      padding-left: 0;
    }
  }
}
/**************************************************************/
.global-container{
  width: 100%;
  position: relative;
}
.inside-global-c-wr{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 11fr;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.top-of-inside-global-c-wr{
  grid-area: top;
  position: relative;
}
.bottom-of-inside-global-c-wr{
  grid-area: bottom;
  position: relative;
}
.global-wr{
  width: 100%;
  padding-bottom: 6px;
}
.leaderboard-row-wr {
  margin-bottom: 6px;
  border-radius: 6px;
  &.active {
    background-image: linear-gradient(to bottom, rgb(var(--skin-color-14)), rgb(var(--skin-color-3)) 259%), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.5), rgba(var(--skin-color-white), 0.4));
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}
.info-row {
  display: table;
  table-layout: fixed;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &.title {
    [dir="ltr"] & {
      padding: 10px 18px 10px 12px;
    }
    [dir="rtl"] & {
      padding: 10px 12px 10px 18px;
    }
    //pointer-events: none;

    > .info-cell {
      text-transform: capitalize;
      color: rgba(var(--text-color), 0.8);
    }
  }

  &.item {
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    //background-image: linear-gradient(to bottom, rgba(var(--skin-color-white), 0.02), rgba(var(--skin-color-white), 0.02)), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.02), rgba(var(--skin-color-white), 0.02));
    background-color: rgba(var(--info-row-color), 1);

    &:hover {
      //background-image: linear-gradient(to bottom, rgba(var(--skin-color-white), 0.1), rgba(var(--skin-color-15), 0.1)), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.1), rgba(var(--skin-color-white), 0.1));
      background-color: rgba(var(--info-row-color), 0.8);
    }

    &.upcoming {
      cursor: default;
    }

    &.selected {
      //background-image: linear-gradient(to bottom, rgba(var(--skin-color-white), 0.08), rgba(var(--skin-color-16), 0.08)), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.05), rgba(var(--skin-color-white), 0.05));
      background-color: rgba(var(--info-row-color), 0.5);
    }
  }
}
.info-cell{
  display: table-cell;
  vertical-align: middle;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: rgb(var(--text-color));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //word-break: break-all;
  direction: ltr;
  [dir="ltr"] & {

  }
  [dir="rtl"] & {
    text-align: right;
  }
  &:first-child{
    width: 35px;

    [dir="ltr"] & {
      padding-left: 0;
    }
    [dir="rtl"] & {
      padding-right: 0;
    }
  }
  &:nth-child(2), &:nth-child(3){
    width: 50px;
  }

  &:last-child{
    [dir="ltr"] & {
      padding-right: 0;
      text-align: right;
    }
    [dir="rtl"] & {
      padding-left: 0;
      text-align: left;
    }
  }
  &:nth-child(3):nth-last-child(2){
    text-align: center;
  }
}
.dollar-icon{
  font-size: 10px;
  line-height: 16px;
  color: rgb(var(--text-color));
}
